
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "src/styles/Mixins";

.top-sellers {
  height: 100%;

  &__list {
    display: flex;
    gap: 5px;
    height: calc(100% - 25px);
    text-decoration: none;
  }

  &__list-item {
    height: 100%;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    justify-content: space-between;
    padding: 15px;
    background-color: rgba(217, 217, 217, 0.04);
    border-bottom: 1px solid rgba(255,255,255, 0.05);
    transition: background-color 0.2s ease-in-out;

    @include max(600px) {
      flex-direction: row;
      padding: 16px;
      height: 100%;
      font-size: 12px;
    }

    &:hover {
      background-color: rgba(217, 217, 217, 0.12);

      .top-sellers__list-item-link {
        color: rgba(219, 180, 102, 1)
      }
    }

    img {
      object-position: left;
      object-fit: contain;
      width: fit-content;
      margin: 3px 0 0;

      @include max(500px) {
        width: auto;
      }
    }
  }

  &__list-item-left {
    display: flex;
    //width: 33%;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__list-item-title {
    font-size: 16px;
    color: #fff;

    @include max(1250px) {
      font-size: 12px;
    }

    @include max(1100px) {
      font-size: 14px;
    }

    @include max(900px) {
      font-size: 12px;
    }

    span {
      margin-right: 3px;
      color: rgba(219, 180, 102, 1);
    }
  }

  &__list-item-link {
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    transition: color 0.2s ease-in-out;
    margin-top: 4px;
    color: rgba(255, 255, 255, 0.2);
  }
}
