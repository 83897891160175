
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
.input-inner {
  position: relative;
  display: flex;
  align-items: center;
  //width: 280px;

  &__icon {
    position: absolute;
    right: 16px;
    top: 6px;
    display: flex;
    width: 18px;
    height: 18px;
    background-image: url('images/search.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

input {
  width: 100%;
  height: 32px !important;
  border-radius: 4px !important;
  border: none !important;
  outline: none !important;
  background-color: rgba(6, 6, 4, 1) !important;
  border-color: rgba(56, 56, 56, 0.3);
  box-shadow: none !important;
  font: 14px/1 'Roboto Condensed' !important;
  padding: 0px 32px 0 16px !important;
}
