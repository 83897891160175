
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "../../styles/Theme";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;

  &_global {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: $color-background;
  }

  &_hide {
    pointer-events: none;
    opacity: 0;
  }

  &__img {
    position: relative;
    width: 120px;
    height: 48px;
  }

  &__img-animation {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 48px;
      height: 48px;
      border: 3px solid #fff;
      border-radius: 50%;
      animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &__img-svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

