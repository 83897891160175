
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@use 'sass:math';
@import "src/styles/Mixins";

.offer-popup {

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px;

    &_1 {
      background-position: bottom;
      background-image: url("./images/bg-1.svg");

      @include max(680px) {
        background-position: 0 120px;
      }
    }

    &_2 {
      background-image: url("./images/bg-2.svg");
    }

    &_3 {
      background-image: url("./images/bg-3.jpg");
      background-repeat: repeat;
      background-size: auto;
    }

    &_4 {
      right: 80px;
      width: 257px;
      height: 100%;
      background-position: bottom;
      background-image: url("./images/bg-4.png");

      @include max(1200px) {
        width: 216px;
      }

      @include max(680px) {
        right: 20px;
      }
    }
  }

  &__shadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 350px;
    border-radius: 0 0 30px 30px;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,1));

    @include max(520px) {
      height: 100%;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    padding: 0 40px;

    @include max(520px) {
      padding: 0 24px;
    }
  }

  &__content-info {
    padding-bottom: 70px;

    @include max(1200px) {
      padding-bottom: 4px;
    }
  }

  &__title {
    margin-top: 20px;
    font-family: 'Oswald', sans-serif;
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 600;
    text-transform: uppercase;

    @include max(1200px) {
      font-size: 38px;
    }

    @include max(580px) {
      font-size: 30px;
    }

    @include max(440px) {
      font-size: 24px;
    }
  }

  &__title-line {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-bottom: 16px;

    @include max(1200px) {
      margin-bottom: 12px;
    }

    @include max(580px) {
      margin-bottom: 8px;
    }

    &_with-icon {
      position: relative;

      &:after {
        position: relative;
        display: block;
        content: '';
        margin-left: 16px;
        width: 48px;
        height: 48px;
        background-image: url("./images/text-separator.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include max(1200px) {
          width: 38px;
          height: 38px;
        }

        @include max(580px) {
          margin-left: 10px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  &__list-title {
    margin-top: 40px;
    margin-bottom: 28px;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgba(219, 180, 102, 1);
  }

  &__list {
    max-width: 262px;
    font-size: 14px;
    color: #FFFFFF;
    font-family: 'Oswald', sans-serif;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &:before {
        position: relative;
        content: '';
        margin-right: 10px;
        width: 6px;
        height: 6px;
        min-width: 6px;
        border-radius: 10px;
        background: rgba(219, 180, 102, 1);
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 18px;

    @include max(440px) {
      justify-content: center;
    }
  }

  &__footer-title {
    position: relative;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-size: 22px;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    margin: 16px 16px 16px 0;

    @include max(580px) {
      font-size: 18px;
    }

    @include max(440px) {
      margin: 16px 0;
    }

    &:after {
      position: relative;
      content: '';
      margin-left: 16px;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("./images/footer-icon.svg");
    }
  }

  &__footer-counter {
    position: relative;
  }

  &__footer-counter-actions {
    display: flex;
    align-items: center;
  }

  &__footer-btn {
    display: flex;
    align-items: center;
    margin-left: 12px;
    height: 42px;
    padding: 0 24px;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    border-radius: 10px;
    border: 1px solid rgba(219, 180, 102, 1);
    color: rgba(219, 180, 102, 1);
    transition: box-shadow 0.25s ease-in-out;

    &:hover {
      color: rgba(219, 180, 102, 1);
      box-shadow: 0 0 15px 0 rgb(219, 181, 102);
    }
  }

  &__footer-counter-notice {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    text-transform: uppercase;
    font-size: 8px;
    text-align: right;
    color: rgba(255,255,255,0.4);
  }

  .ant-modal-content {
    border-radius: 20px;
    border: 1px solid rgba(219, 181, 102, 0.5);
  }
}

