
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import 'Reset';
@import 'Theme';
@import 'Mixins';

@font-face {
  font-family: "Montserrat-400";
  src: url("Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat-700";
  src: url("Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat-800";
  src: url("Montserrat-Bold.woff2") format("woff2");
  font-weight: 800;
}

@font-face {
  font-family: "Montserrat-900";
  src: url("Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: 900;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.root {
  position: relative;
}

#bubbles-app {
  position: relative;
}

.app {
  position: relative;
  z-index: 5;
  width: 100%;
  padding-bottom: 16px;

  &_inited {

  }

  &__top {
    position: relative;
    z-index: 10;
    max-width: 1345px;
    margin: 0 auto;
    padding: 0 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 780px) {
      display: flex;
      flex-direction: column;
    }
  }

  &::before {
    position: fixed;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    top: 100px;
    left: 0;
    width: 230px;
    height: 400px;
    background-image: url('../images/shadow-left1.svg');

    @include min(700px) {
      width: 300px;
      height: 500px;
    }

    @include min(992px) {
      width: 437px;
      height: 634px;
    }
  }

  &::after {
    position: fixed;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    top: 0;
    right: 0;
    width: 360px;
    height: 500px;
    background-image: url('../images/shadow-right.svg');
    opacity: 0.75;

    @include min(700px) {
      width: 630px;
      height: 770px;
    }

    @include min(992px) {
      width: 760px;
      height: 921px;
    }
  }
}

.container {
  max-width: 1345px;
  width: 100%;
  margin: 0 auto;
}

.btn {
  padding: 16px 50px;
  background-color: #FFDD2D;
  color: #000000;
  transition: background-color 0.2s;
  border-radius: 12px;

  &:hover {
    background-color: #FCC521;
    cursor: pointer;
  }

  &_black {
    background-color: #000000;
    color: #ffffff;

    &:hover {
      background-color: #383838;
    }
  }

  &_white {
    background-color: #ffffff;
    border: 1px solid #A1A1B2;
    padding: 8px 16px;

    &:hover {
      background-color: #DDDFE0;
      border-color: transparent;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.footer {
  height: 100%;
  flex-direction: row !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 16px !important;

  p {
    color: rgba(217, 217, 217, 0.4);
  }

  &__copyright {
    font: 14px/1 'Oswald';
    font-weight: 400;
    line-height: 0;
  }

  &__developer {
    font: 16px/1 'Oswald';
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
}

.ant-select {
  //min-width: 120px;
}

.ant-select-selector {
  width: 120px !important;
  border-radius: 4px !important;
  border: none !important;
  background: rgba(6, 6, 4, 1) !important;
}

.ant-select-single {
  height: 32px !important;
}

.ant-select-selection-item {
  font-size: 14px !important;
  color: #ffffff !important;
}

.ant-select-dropdown {
  color: #ffffff !important;
  background: rgba(6, 6, 4, 1) !important;
}

.ant-select-item-option-selected {
  color: #ffffff !important;
  background: rgb(44, 44, 43) !important;
}

.ant-select-item {
  min-height: 32px !important;
  font-size: 14px !important;
  color: #ffffff !important;

  &:hover {
    background: rgb(30, 30, 29) !important;
  }
}

.bubble-window-price {
  display: none !important;
}

input {
  color: #FFFFFF;
}

.loader-glob {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.app-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

@keyframes show-left {
  0% {
    transform: scale(0.8) translateX(-33%);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0.8) translateX(-33%);
  }
}

@keyframes show-right {
  0% {
    transform: scale(0.8) translateX(33%);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: scale(0.8) translateX(33%);
  }
}
