
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
.countdown-flip-clock {
    height: 42px  !important;
    padding: 0 !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container.hours:after,
.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container.minutes:after {
    content: none !important;
}

.countdown-flip-clock .countdown-wrapper {
    gap: 0 !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container {
    flex-wrap: nowrap !important;
    gap: 0 !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .flip-title {
    display: none !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group {
    width: 42px !important;
    height: 42px !important;
    background: #000 !important;
    border-radius: 5px !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .animation-card.flip-fold,
.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .animation-card.flip-unfold {
    background: #000 !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .animation-card.flip-fold {
    border: 0.5px solid rgba(255,255,255,0.5) !important;
    border-bottom: 0.5px solid rgba(255,255,255,0.5) !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .animation-card.flip-unfold {
    border: 1px solid rgba(255,255,255,0.5) !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}


.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .static-card {
    border: 1px solid rgba(255,255,255,0.5) !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .static-card span,
.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .animation-card span {
    font-family: Oswald, sans-serif !important;
    font-weight: 400 !important;
    font-size: 30px !important;
    color: #fff !important;
}

.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .static-card.upper {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}


.countdown-flip-clock .countdown-wrapper .countdown-container .flip-unit-container .digit-group .static-card.lower {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
