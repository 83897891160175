
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
.telegram-block {
  height: 100%;

  &__list {
    padding-top: 10px;
    display: grid;
    grid-template-rows: repeat(4, 60px);

  }

  &__list-item {
    display: flex;
    align-items: center;
    //min-height: 60px;
    padding: 0 16px;
    font-size: 16px;
    border-bottom: 1px solid rgba(255,255,255, 0.05);
    transition: background-color 0.25s ease-in-out;
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
      background-color: rgba(217, 217, 217, 0.12);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

#telegram {
  .title {
    padding: 16px 16px 0 16px;
  }
}
