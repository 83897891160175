
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "src/styles/Mixins";

.knowledge-base {
  position: relative;
  width: 100%;
  max-width: 1345px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    margin-bottom: 40px;

    @include max(1280px) {
      margin-bottom: 20px;
    }

    @include max(768px) {
      margin-bottom: 82px;
    }
  }

  &__title {
    position: relative;
    width: 100%;
  }

  &__title-text {
    display: inline;
    padding: 0 0 2px 0;
    font-size: 16px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    border-bottom: 2px solid #dbb566;
  }

  &__title-shadow {
    position: absolute;
    top: -30px;
    left: 0;
    font-size: 56px;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    color: rgba(255, 255,255,0.1);

    @include max(768px) {
      font-size: 48px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 300px;
    border-radius: 24px;
    border: 2px solid #FFFFFF;
    font-family: 'Roboto Condensed',sans-serif;
    font-style: normal;
    font-weight: 400;
    overflow: hidden;
    transition: 0.35s ease-in-out;

    &::before {
      position: absolute;
      content: '';
      z-index: 4;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg,rgba(0,0,0,0) 0,#000 100%);
    }

    &:hover {
      border-color: #dbb566;

      .knowledge-base__item-image {
        transform: scale(1.05);
      }
    }
  }

  &__item-image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 3;
    transition: 0.5s ease-in-out;
    opacity: 0;

    &_loaded {
      opacity: 1;
    }
  }

  &__item-info {
    position: relative;
    z-index: 5;
    padding: 24px 36px;
  }

  &__item-title {
    margin-bottom: 16px;
    line-height: 1.35;
    font-size: 24px;
  }

  &__item-meta {
    display: flex;
    justify-content: flex-start;

    span {
      font-size: 18px;
      margin-right: 25px;
    }
  }

  &__item-link {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-button-prev {
    position: absolute;
    z-index: 100;
    top: -108px;
    right: 98px;
    width: 68px;
    height: 68px;
    border-radius: 68px;
    border: 1px solid #dbb566;
    background-image: url("images/left-active.svg");
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #dbb566;
      background-image: url("images/left.svg");
    }

    @include max(1280px) {
      top: -80px;
      right: 62px;
      width: 45px;
      height: 45px;
      background-size: 12px;
    }

    @include max(768px) {
      right: calc(100% - 46px);
      top: -75px;
    }
  }

  .swiper-button-next {
    position: absolute;
    z-index: 100;
    top: -108px;
    right: 0;
    width: 68px;
    height: 68px;
    border-radius: 68px;
    border: 1px solid #dbb566;
    background-image: url("images/right-active.svg");
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #dbb566;
      background-image: url("images/right.svg");
    }

    @include max(1280px) {
      top: -80px;
      width: 45px;
      height: 45px;
      background-size: 12px;
    }

    @include max(768px) {
      right: calc(100% - 110px);
      top: -75px;
    }
  }
}
