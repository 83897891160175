html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
menu,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  outline: 0;
}

:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: #000;
  background: #fff;
}

ol,
ul {
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: separate;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
}

blockquote,
q {
  quotes: '' '';
}

button {
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
}

a,
button,
input[type='checkbox'] {

  &:focus-visible {
    box-shadow: 0 0 5px rgba(#00ebea, .7);
    outline: 2px solid #00badc;
    outline-offset: -1px;
  }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input,
button,
select {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

img {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}
