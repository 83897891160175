@import 'Theme';
@mixin max($size) {

  @media (max-width: $size) {
    @content;
  }
}

@mixin maxh($size) {

  @media (max-height: $size) {
    @content;
  }
}

@mixin min($size) {

  @media (min-width: $size) {
    @content;
  }
}

@mixin custom-scroll {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $color-accent $color-thumb;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    margin-bottom: 2px;
    border-radius: 5px;
    //background-color: $color-accent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $color-thumb;
  }
}

@mixin custom-horizontal-scroll {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: $color-accent $color-thumb;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    height: 5px;
    border-radius: 5px;
    //background-color: $color-accent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $color-thumb;
  }
}

@mixin hide-scroll {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
