
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
.bubbles {
  width: 100%;
  height: 100%;
  min-height: 420px;

  &__select {
    width: 120px;

    //.ant-select-selector {
    //  border-color: #222222 !important;
    //  background-color: #222222 !important;
    //  color: #fff !important;
    //}
  }

  &__item {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: burlywood;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bubbles-container-hi {
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;

  &_show {
    visibility: visible;
    opacity: 1;
    display: block;
  }


}
