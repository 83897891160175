
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "src/styles/Mixins";

.crypto-list {
  width: 100%;

  &__table {
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    @include custom-scroll();
    @include custom-horizontal-scroll();
  }

  &__table-header {
    position: sticky;
    top: -1px;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px 10px 0;
    width: 100%;
    min-width: 869px;
    background-color: rgb(32 32 27);
  }

  &__table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 0;
    min-width: 869px;
    cursor: pointer;
  }

  &__table-col {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-right: 14px;
    font-size: 13px;

    &_header {
      font-size: 14px;

      &:first-child {
        position: sticky;
        top: 0;
        left: 0;
        padding-left: 16px;
        background-color: rgb(32 32 27);
      }
    }

    &_plus {
      color: rgba(128, 255, 212, 1);
    }

    &_minus {
      color: rgba(255, 103, 86, 1);
    }

    &_zero {
      color: #ffffff;
    }

    &_arrow {
      min-width: 20px;
      width: 20px;
      padding-right: 0;
    }

    &:first-child:not(.crypto-list__table-col_header) {
      position: sticky;
      left: 0;
      min-width: 150px;
      padding-left: 16px;
    }

    &_scr {
      background-color: rgb(32, 32, 27);
    }

    &:first-child {
      position: sticky;
      left: 0;
      min-width: 150px;
      padding-left: 16px;
    }

    &:nth-child(2) {
      min-width: 80px;
    }

    &:nth-child(3) {
      min-width: 100px;
    }

    &:nth-child(4) {
      min-width: 100px;
    }
  }

  &__table-col-arrow {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../images/arrow-right.svg');
  }

  &__logo {
    width: 32px;
    height: 32px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    margin-left: 12px;
  }

  &__info-title {
    text-transform: uppercase;
    font-size: 13px;
    margin: 0 0 4px !important;
  }

  &__info-description {
    margin: 0 !important;
    color: rgba(255, 255, 255, 0.5)
  }

  &__table-rows {
    height: 378px;
  }

  &__table-row {
    height: 57px;
    border-bottom: 1px solid rgba(227, 230, 233, 0.07);
    //transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: #343531;

      .crypto-list__table-col {
        background-color: #343531;
      }
    }
  }
}

.full-screen {
  &-chart {
    height: calc(100vh - 64px) !important;
    @include max(700px) {
      height: calc(100vh - 96px) !important;
    }
    @include max(574px) {
      height: calc(100vh - 136px) !important;
    }
  }
  &-bubbles-app {
    height: 100% !important;
  }
  .crypto-list__table-rows {
    height: calc(100vh - 56px - 34px);
  }

  .crypto-list {
    position: absolute;
    top: 64px;
    @include max(700px) {
      top: 136px
    }

    @include max(574px) {
      top: 164px
    }

    &__table {
      height: calc(100vh - 64px);

      @include max(700px) {
        height: calc(100vh - 136px);
      }

      @include max(574px) {
        height: calc(100vh - 164px);
      }
    }
  }
}
