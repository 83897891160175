
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
.altcoin-index {
  display: flex;
  height: 100%;
  padding-bottom: 16px;
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-weight: 200;

  &__index {
    width: 63%;
    margin-top: 24px;
    margin-right: 12px;
    padding: 12px;
    background-color: rgba(14, 14, 14, 0.3);
    border-radius: 6px;
  }

  &__index-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1;
    font-weight: 300;

    &::after {
      position: relative;
      content: '';
      width: 30px;
      height: 30px;
      background-image: url("./images/logo.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  &__index-percentage {
    margin-bottom: 24px;
    font-size: 48px;
    color: rgba(255, 255, 255, 0.6);

    span {
      color: rgba(219, 180, 102, 1);
    }
  }

  &__index-progress {
    position: relative;
    margin-bottom: 20px;
    height: 5px;
    width: 100%;
    border-radius: 5px;
    background-color: rgba(48, 70, 207, 1);
  }

  &__index-progress-line {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;

    &_first {
      width: 75%;
      background-color: rgba(135, 146, 212, 1);
    }

    &_second {
      width: 50%;
      background-color: rgba(255, 223, 178, 1);
    }

    &_third {
      width: 25%;
      background-color: rgba(255, 178, 71, 1);
    }
  }

  &__index-progress-point {
    position: absolute;
    z-index: 10;
    top: -4px;
    width: 14px;
    height: 14px;
    border-radius: 15px;
    border: 1px solid rgba(219, 180, 102, 1);
    background: radial-gradient(50% 50% at 50% 50%, #262626 0%, #0C0C0C 100%);
  }

  &__index-progress-notice {
    display: flex;
    justify-content: space-between;
    line-height: 1.3;
  }

  &__dominance {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 37%;
    padding: 0 8px 16px 8px;
  }

  &__dominance-title {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 300;



    &::after {
      content: '';
      position: relative;
      margin-left: 8px;
      width: 8px;
      height: 15px;
      background: url("./images/arrow.svg") no-repeat center center;
    }

    @media screen and (max-width: 375px) {
      padding-top: 12px;
      font-size: 16px;

      &::after {
        height: 12px;
      }
    }
  }

  &__dominance-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 61px;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 6px;
    background-color: rgba(23, 23, 23, 1);
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;

    &_btc {
      background-image: url("./images/mdi_bitcoin.svg");
    }

    &_eth {
      background-image: url("./images/mdi_ethereum.svg");
    }
  }

  &__dominance-item-title {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__dominance-item-value {
    text-align: right;
    font-size: 25px;
    color: rgba(219, 180, 102, 1);
  }
}
