
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "src/styles/Mixins";

.bubbles-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  @include max(700px) {
    flex-direction: column;
    align-items: flex-start;

    .title {
      margin-bottom: 16px;
    }
  }

  &_is-bubbles {
    //background-color: #D9D9D90A;
    background-color: rgb(32 32 27);

    @include max(1348px) {
      background-color: rgb(32 32 27);
    }
  }

  &__select {
    //margin-left: 8px;
  }

  &__select-input {
    display: grid;
    gap: 16px;
    grid-template-columns: 120px 60px 32px;
    grid-column: 1/3;

    @include max(900px) {
      gap: 12px;
      grid-template-columns: auto 60px 32px;
    }

    @include max(574px) {
      width: 100%;
      gap: 8px;
      grid-template-columns: 1fr 60px 32px;
    }

    &_without-bubbles {
      grid-template-columns: 280px 60px 32px;

      @include max(1140px) {
        grid-template-columns: 250px 60px 32px;
      }

      @include max(900px) {
        width: 100%;
        grid-template-columns: 1fr 60px 32px;

        .input-inner {
          max-width: 100%;
          width: 100% !important;

          .input {
            width: 100%;
            max-width: 100%;
          }
        }
      }

      @include max(574px) {
        width: 100%;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    @include max(700px) {
      width: 100%;
      display: grid;
      margin-top: 16px;
      gap: 12px;
      grid-template-columns: 1fr auto;

      .ant-select-selector {
        width: 100% !important;
      }
    }

    @include max(574px) {
      display: flex;
      gap: 8px;
      flex-direction: column;

      .ant-select {
        width: 100%;
      }
    }

    .input {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 280px;
      width: 100%;
    }
  }

  &__toggle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 60px;
    height: 32px;
    padding: 4px;
    background-color: #060604;
    border-radius: 4px;
  }

  &__toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 24px;
    border-radius: 4px;
    background-color: #060604;

    &_full {
      height: 32px;
      width: 32px;
      min-width: 32px;
    }

    &_active {
      background-color: #DBB466;
    }
  }

  &__toggle-icon {
    display: block;
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    &_bubble {
      background-image: url('../../../../images/bubble-icon.svg');
    }

    &_bubble-active {
      background-image: url('../../../../images/bubble-icon-active.svg');
    }

    &_burger {
      background-image: url('../../../../images/burger-icon.svg');
    }

    &_burger-active {
      background-image: url('../../../../images/burger-icon-active.svg');
    }

    &_full-active {
      background-image: url('../../../../images/full-screen-active.svg');
    }

    &_full {
      background-image: url('../../../../images/full-screen.svg');
    }
  }
}
