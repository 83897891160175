
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "../../styles/Theme";

.title {
  position: relative;
  z-index: 10;
  pointer-events: none;
  font: 16px/1 'Oswald';
  font-weight: 700;
  text-transform: uppercase;
  color: $color-gray;
}
