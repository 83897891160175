
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                

.main-banner {
  position: relative;
  width: 100%;

  @media screen and (max-width: 920px) {
    display: none
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: height 0.3s ease-in;
  }

  &__btn {
    position: absolute;
    right: 12.5%;
    top: 50%;
    z-index: 5;
    transform: translateY(-50%);
    width: 11.8%;
    height: auto;
    text-align: center;
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    color: rgb(6, 6, 4);
    font-size: 1.15vw;
    line-height: 2.8vw;
    border-radius: 0.8vw;
    text-decoration: none;
    background: linear-gradient(170deg, #FFCA58 25.24%, #997935 74.88%);
    transition: box-shadow 0.15s;

    &:hover {
      box-shadow: 0 0 15px 0 rgba(255, 188, 36, 0.85);
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
    width: 16px;
    height: 16px;
    padding: 12px;
    border-radius: 16px;
    background-image: url('../../images/icon-close.svg');
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #c09842;
    }
  }
}
