
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "src/styles/Mixins";

.grid-item {
  position: relative;
  width: 100%;
  max-width: 1400px;
  opacity: 0;

  &_styles {
    background-color: rgba(217, 217, 217, 0.04);
    border-radius: 8px;

    .grid-item__content {
      border-radius: 8px;
      overflow: hidden;
    }
  }

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 700;
    background-color: #141510;
  }

  &_animate {
    animation-name: show-item;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  &:hover {
    .grid-item__decor {
      opacity: 1;
    }
  }

  &_altcoin-index {
    height: 260px;
    grid-row: 1;
    grid-column: span 1;
  }

  &_fear-and-greed {
    height: 260px;
    grid-row: 1;
    grid-column: span 1;
    animation-delay: 0.2s;

    &::before {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      width: 1px;
      height: 1px;
      box-shadow: 0 0 150px 150px rgba(219, 180, 102, 0.1);
    }
  }

  &_top-sellers {
    height: 260px;
    grid-row: 1;
    grid-column: span 1;
    animation-delay: 0.4s;

    @include max(1100px) {
      height: auto;
      grid-row: 2;
    }

    @include max(780px) {
      height: 230px;
    }

    &::before {
      content: '';
      display: block;
      bottom: 0;
      right: 0;
      width: 1px;
      height: 1px;
      box-shadow: 0 0 150px 150px rgba(219, 180, 102, 0.1);
    }
  }

  &_bubbles {
    grid-column: span 2;
    grid-row: 2/4;
    animation-delay: 0.6s;

    @include max(1100px) {
      grid-row: 3;
    }
  }

  &_bubbles.full-screen {
    height: 100vh;
  }

  &_telegram {
    height: 282px;
    grid-row: 2;
    grid-column: span 1;
    animation-delay: 0.8s;

    .title {
      padding: 16px 0 4px 16px;
    }

    @include max(1100px) {
      grid-row: 2;
    }
  }

  &_youtube {
    height: 230px;
    grid-row: 3;
    grid-column: span 1;
    animation-delay: 1s;

    @include max(1100px) {
      height: 400px;
      grid-row: 4;
      grid-column: span 2;
    }

    @include max(780px) {
      height: 240px;
    }
  }

  &_liquidation-map {
    grid-column: span 3;
    grid-row: 4;
    animation-delay: 1.2s;

    @include max(1100px) {
      grid-row: 5;
      grid-column: span 2;
    }
  }

  &_knowledge-base {
    grid-column: span 3;
    grid-row: 5;
    margin: 16px 0;
    animation-delay: 1.4s;

    @include max(1100px) {
      grid-row: 6;
      grid-column: span 2;
    }
  }

  &_footer {
    grid-column: span 3;
    grid-row: 6;
    animation-delay: 1.6s;

    @include max(1100px) {
      grid-row: 7;
      grid-column: span 2;
    }
  }

  &__decor {
    position: absolute;
    top: -8px;
    left: 16px;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    &_left {
      position: absolute;
      top: -2px;
      left: -26px;
      width: 25px;
      height: 165px;
      background-image: url("images/border-left.png");
      background-size: contain;
      background-position: 0 0;
    }

    &_right {
      position: absolute;
      top: -2px;
      right: 6px;
      width: 25px;
      height: 165px;
      background-image: url("images/border-right.png");
      background-size: contain;
      background-position: 0 0;
    }

    &_top {
      position: absolute;
      top: -2px;
      left: -1px;
      width: calc(100% - 30px);
      height: 35px;
      background-image: url("images/border-top.png");
      background-size: contain;
      background-position: 0 0;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow: hidden;

    &_no-padding {
      padding: 0;
    }
  }
}

@keyframes show-item {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
