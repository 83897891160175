
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "src/styles/Mixins";

.site-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1345px;
  width: 100%;
  margin: 16px auto;
  padding: 0 16px;
  border-radius: 8px;
  background-color: transparent;
  z-index: 650;

  .logo {
    width: 119px;
    height: 48px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__menu-item {
    margin-left: 3px;
    transition: color 0.15s;

    &:hover {
      color: #DBB466;
    }
  }

  &__drawer-close {
    position: absolute;
    top: 18px;
    right: 16px;
    width: 24px;
    height: 24px;
    padding: 0;
  }
}

.header-icons {
  display: flex;
  align-items: center;

  &__item {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

    &_youtube {
      background-image: url('../../images/youtube.svg');
    }

    &_telegram {
      margin-right: 0;
      background-image: url('../../images/telegram.svg');
    }
  }

  &__item-burger {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    @include max(920px) {
      display: flex;
    }
  }

  &__item-burger-line {
    width: 100%;
    height: 2px;
    background-color: #dbb566;
    margin: 3px 0;
  }
}
