
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
@import "src/styles/Mixins";

.header-links {
  display: flex;
  align-items: center;

  @include max(920px) {
    display: none;
  }

  &_mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    position: relative;
    margin-right: 20px;
    padding: 16px 0;
    color: #eeebeb;
    cursor: pointer;

    &:hover {
      color: #DBB466;
    }

    &_sub {
      &:hover {
        .header-links__item-sub {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }

        .header-links__item-title::after {
          background: url('./images/arrow-active.svg') no-repeat center center;
          rotate: 180deg;
        }
      }
    }
  }

  &__item-title {
    display: flex;
    align-items: center;

    &::after {
      position: relative;
      content: '';
      display: block;
      margin-left: 6px;
      width: 12px;
      height: 7px;
      background: url('./images/arrow.svg') no-repeat center center;
      transition: 0.15s;
    }

    &_open {
      &::after {
        background: url('./images/arrow-active.svg') no-repeat center center;
        rotate: 180deg;
      }
    }
  }

  &__item-link {
    display: block;
    transition: color 0.15s;
    color: #eeebeb;
    text-decoration: none;

    &:hover {
      color: #DBB466;
    }

    &_sub {
      margin-bottom: 16px;
    }
  }

  &__item-sub {
    position: absolute;
    transform: translateY(-16px);
    left: -20px;
    top: 100%;
    min-width: 200px;
    padding: 16px 16px 0 16px;
    background-color: #111;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    opacity: 0;
    visibility: hidden;
    transition: 0.15s;

    .header-links__item-link {
      margin-bottom: 16px;
    }
  }

  &__item-sub-mobile {
    position: relative;
    margin-top: 24px;
    margin-left: 16px;

    .header-links__item-link {
      margin-bottom: 16px;
    }
  }
}
