
                  $sm: 320px;
$xsm: 480px;
$md: 768px;
$xmd: 960px;
$no: 1024px;
$xno: 1280px;
$lg: 1440px;
$xlg: 1920px;;
                
.offer-widget {
  position: relative;
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 16px;

  &__k-decor {
    position: absolute;
    top: 0;
    left: 0;
    width: 47px;
    height: 48px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('./images/left-top-decor.svg');
  }

  &__shadow-decor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 126px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('./images/left-top-shadow.svg');
  }

  &__graph-decor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 122px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('./images/graph-decor.svg');
    transition: 0.5s transform ease-in-out;

    &_up {
      animation: graph-up 0.5s forwards;
    }

    &_down {
      animation: graph-down 0.55s forwards;
    }
  }

  &__iphone-decor {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 71px;
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('./images/iphone.png');
    transition: opacity 0.35s;
    opacity: 0;

    &_show {
      animation: show-iphone 1s forwards;
    }

    &_hide {
      animation: hide-iphone 2s forwards;
    }
  }

  &__gradient-decor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, transparent, rgb(0, 0, 0));
  }

  &__container {
    position: fixed;
    z-index: 1000;
    left: auto;
    bottom: 16px;
    width: 110px;
    height: 180px;
    border-radius: 6px;
    background: #0d0806;
  }

  &__content {
    position: relative;
    overflow: hidden;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(219, 181, 102, 1);
    box-shadow: 0 0 8px 0 rgb(219, 181, 102, 0.7);
  }

  &__content-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 11px;
    height: 11px;
    background-image: url("../../images/icon-close.svg");
    background-size: 10px 10px;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color 0.15s ease-in-out;
    padding: 10px;
    border-radius: 12px;

    &:hover {
      background-color: rgb(219, 181, 102);
    }
  }

  &__content-title {
    font-family: 'Oswald', sans-serif;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(219, 181, 102, 1);
    text-shadow: 0 0 rgb(219, 181, 102);
    font-weight: 700;

    &_up {
      animation: bounceInUpTitle 1.4s forwards;
    }

    &_down {
      animation: backOutDown .75s ease-in-out forwards;
    }
  }

  &__content-discount {
    position: absolute;
    top: 0;
    left: 4px;
    width: 100px;
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('./images/discount.svg');
    background-position: center;

    &_show {
      animation: bounceInUpDiscount 1.4s forwards;
    }

    &_hide {
      animation: backOutDown 2s forwards;
    }
  }

  &__content-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    line-height: 1;
    padding-bottom: 3px;
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    font-size: 11px;
    color: rgba(219, 181, 102, 1);
    border: 1px solid rgba(219, 181, 102, 1);
    box-shadow: 0 0 5px 0 rgb(219, 181, 102);
    border-radius: 4px;
    background: rgba(0,0,0,0.45);
    outline: none;
    transition: 0.15s box-shadow ease-in-out;

    &:hover {
      color: rgba(219, 181, 102, 1);
      box-shadow: 0 0 15px 0 rgb(255, 188, 36);
    }
  }
}

@keyframes bounceInUpTitle {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    transform: translate3d(0, 300px, 0);
  }

  30% {
    opacity: 1;
    transform: translate3d(0, -5px, 0);
  }

  55% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUpDiscount {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 500px, 0);
  }

  30% {
    opacity: 1;
    transform: translate3d(0, -5px, 0);
  }

  55% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes graph-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(25px);
  }
}

@keyframes graph-down {
  0% {
    transform: translateY(25px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes backOutDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: translateY(600px) scale(0.9);
    opacity: 0.9;
  }
}

@keyframes show-iphone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide-iphone {
  0% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }
}
